import React from 'react'
import { randomAorB } from '../helperFunctions'

export interface SettingsInterface {
    contractDisabled?: boolean
    leadsource?: number
    directFlow?: string
    returnUrl?: string
    version?: string
    loadHeatpumpService?: boolean
    heatpumpOfferUrl?: boolean
    isVolksbankBrand?: boolean
    redirectUrl?: string
}

export const SettingsContext = React.createContext({} as SettingsInterface)

const SettingsContextProvider = (
    props: SettingsInterface & {
        children: React.ReactNode
    }
) => {
    const queryParams = new URLSearchParams(window.location.search)

    const options = {
        contractDisabled: props.contractDisabled || false,
        leadsource: props.leadsource || 179,
        directFlow:
            props.directFlow ||
            queryParams.get('directFlow') ||
            'completeAdvice',
        returnUrl: props.returnUrl || '',
        version:
            props.version || queryParams.get('version') || randomAorB() || 'A',
        loadHeatpumpService: true,
        /* !!queryParams.get('loadHeatpumpService') ||
            props.loadHeatpumpService ||
            false*/
        heatpumpOfferUrl: props.heatpumpOfferUrl,
        isVolksbankBrand: props.isVolksbankBrand || false,
        redirectUrl: props.redirectUrl || ''
    }

    console.log(options.version)

    return (
        <SettingsContext.Provider value={{ ...options }}>
            {options && props.children}
        </SettingsContext.Provider>
    )
}

export default SettingsContextProvider
